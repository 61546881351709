@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/aos/src/sass/aos";
@import "slick";
@import "override";
html, body {
    font-family: 'Rajdhani', sans-serif;
}
nav {
    position: relative;
    z-index: 5;
}
.nav-item a {
    font-size: 1.2rem;
    font-weight: bold;
    border: 1px solid transparent;
    padding: .3rem 1.5rem !important;
    &.active {
        background: rgb(3, 98, 143);
        border:1px solid rgb(3, 152, 211);
    }
}
.navbar-brand {
    img {
        max-height: 70px;
    }
}
body {
    background: #000;
    color: #FFF;
}
.navbar {
    @include media-breakpoint-up(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .coffee-link {
        background: rgb(3, 98, 143);
        border:1px solid rgb(3, 152, 211);
        box-shadow: 0 0 20px rgba(black, .5);
        color: #FFF;
        padding: .5rem 1rem;
        text-decoration: none;
        transition: .3s;
        font-size: .9rem;
        &:hover {
            background: rgb(2, 121, 177);
            box-shadow: 0 0 20px rgba(black, .8);
            color: #FFF;
        }
    }
}
.nav-item {
    @include media-breakpoint-up(lg) {
        padding: 0 2rem;
    }
}

.play-btn {
    background: rgb(63, 136, 3);
    border:1px solid rgb(56, 173, 52);
    color: #FFF;
    padding: 1rem 2rem;
    text-decoration: none;
    box-shadow: 0 0 20px rgba(black, .5);
    transition: .3s;
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(black, .3);
    small {
        font-weight: normal;
    }
    &:hover {
        background: rgb(70, 155, 1);
        box-shadow: 0 0 20px rgba(black, .8);
        color: #FFF;
    }
}

// SPLASH

#splash {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 105vh;
    #splashvid {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
    }
    .spash-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 1;
        height: 50%;
        padding: 1rem;
        .btn {
            small {
                font-size: .7rem;
            }
        }
    }
    h1 {
        color: white;
        font-weight: normal;
        font-size: 1.2rem;
        letter-spacing: .3rem;
    }
}

.img-bg {
    position: absolute;
    top:0;
    object-fit: cover;
    opacity: .2;
    width: 100%;
    height: 100%;
    z-index: -1;
}

// REA

#rea {
    position: relative;
    padding-top: 10%;;
    &::before {
        content: '';
        background-color: #000;
        height: 40px;
        width: 100%;
        position: absolute;
        top: -20px;
        z-index: 1;
        transform: skew(0,-1deg);
    }
    @include media-breakpoint-up(md) {
        height: 105vh;
    }
    h2 {
        font-weight: bold;
        text-align: center;
        margin-bottom: 4rem;
        text-transform: uppercase;
        color: #FFF;
        position: relative;
        &::after {
            content:'';
            position: absolute;
            bottom: -10px;
            width: 100px;
            height: 4px;
            background: rgb(1, 37, 104) !important;
            left: 50%;
            margin-left: -50px;
        }
    }
}

// LORE

#lore {
    position: relative;
    padding-top: 10%;;
    &::before {
        content: '';
        background-color: #000;
        height: 40px;
        width: 100%;
        position: absolute;
        top: -20px;
        z-index: 1;
        transform: skew(0,-1deg);
    }
    @include media-breakpoint-up(md) {
        height: 105vh;
    }
    .jumbotron {
        background: rgba(black, .3);
        padding:1rem;
    }
    h2 {
        font-weight: bold;
        text-align: center;
        margin-bottom: 4rem;
        text-transform: uppercase;
        color: #FFF;
        position: relative;
        &::after {
            content:'';
            position: absolute;
            bottom: -10px;
            width: 100px;
            height: 4px;
            background: #FFF !important;
            left: 50%;
            margin-left: -50px;
        }
    }
    .img-bg {
        opacity: .6;
    }
}

// Robots
#robots {
    position: relative;
    padding-top: 5%;
    &::before {
        content: '';
        background-color: #000;
        height: 40px;
        width: 100%;
        position: absolute;
        top: -20px;
        z-index: 1;
        transform: skew(0,-1deg);
    }
    @include media-breakpoint-up(md) {
        height: 105vh;
    }
    h2 {
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: #FFF;
        position: relative;
        margin-top: 20%;
        &::after {
            content:'';
            position: absolute;
            bottom: -10px;
            width: 100px;
            height: 4px;
            background: #FFF !important;
            left: 50%;
            margin-left: -50px;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 5rem;
            margin-top: auto;
        }
    }
    h3 {
        font-weight: bold;
        text-shadow: 0 0 2px rgba(rgb(3, 69, 122), .5);
        color:rgb(16, 144, 248);
    }
    .slider {
        position: relative;
        img.robots {
            width: 310px;
            padding:0 1rem 0 0;
        }
        .d-item-slide {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
            > div {
                &:first-child {
                    position: relative;
                    &:before {
                        content: '';
                        width: 60%;
                        margin-left: 20%;
                        height: 80%;
                        position: absolute;
                        top: 10%;
                        background: rgba(rgb(39, 103, 199), .5);
                        border-radius: 50%;
                        z-index: -1;
                    }
                }

            }
            > div {
                &:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    justify-items: center;
                    justify-content: center;
                    text-shadow: 0 0 5px rgba(black, .5);
                    font-size: 1.1rem;
                }
            }
            p {
                font-size: .9rem;
                @include media-breakpoint-up(md) {
                    font-size: 1rem;
                }
            }
        }
    }
    .img-bg {
        opacity: .7;
    }
}
.slick-list { 
    padding:45px 60px !important;
    margin-left:30px !important;
  }
.slick-cloned {opacity: 0;}
.slick-slide {
    opacity: .15;
    transition: .3s;;
}
.slick-current {
    width: 100%;
    opacity: 1;
    @include media-breakpoint-up(md) {
        -webkit-transform: scale(1.25);
        -moz-transform: scale(1.25);
        transform: scale(1.25);
    }
}
.slider {
    max-width: 100%;
    overflow: hidden;
}
.slider-nav {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
    .slick-slide {
        &:hover {
            cursor: pointer;
        }
    }
    img {
        border-radius: 50%;
    }
}


/* Join com */ 

// Robots
#kit {
    position: relative;
    height: 100vh;
    padding-top: 10%;;
    &::before {
        content: '';
        background-color: #000;
        height: 40px;
        width: 100%;
        position: absolute;
        top: -20px;
        z-index: 1;
        transform: skew(0,-1deg);
    }
    h2 {
        font-weight: bold;
        text-align: center;
        margin-bottom: 4rem;
        text-transform: uppercase;
        color: #FFF;
        position: relative;
        margin-bottom: 5rem;
        margin-top: 5%;
        &::after {
            content:'';
            position: absolute;
            bottom: -10px;
            width: 100px;
            height: 4px;
            background: #FFF !important;
            left: 50%;
            margin-left: -50px;
        }
        @include media-breakpoint-up(md) {
            margin-top: auto;
        }
    }
    .rs-link {
        font-size: 64px;
        color: #FFF;
    }
}

// Footer
#footer {
    position: relative;
    padding: 2% 0;
    &::before {
        content: '';
        background-color: #000;
        height: 40px;
        width: 100%;
        position: absolute;
        top: -20px;
        z-index: 1;
        transform: skew(0,-1deg);
    }
    height: 20vh;
    color: #FFF;
    img {
        height: 120px;
        opacity: .1;
    }
}