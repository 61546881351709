.bg-black {
    background-color: rgba(black, .7) !important;
}
.card {
    border: 1px solid rgb(1, 37, 104) !important;
    border-radius: 0 !important;
}
.card-body {
    color: #FFF;
    background-color: rgb(1, 20, 56);
    padding: 2rem 1rem;
}

.card-white {
    .card {
        border: 1px solid rgb(0, 56, 160) !important;
        border-radius: 0 !important;
        margin-top: 10%;
    }
    .card-body {
        color: #333;
        background-color: #FFF;
    }
    .lni {
        color: red;
    }
}